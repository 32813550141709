<template>
  <div class="flex flex-wrap items-center">
    <template v-for="tag in tags">
      <MTooltip :key="tag">
        <template v-slot:trigger>
          <MTag
            :key="tag"
            :variant="variant"
            class="flex items-center mx-1"
            :class="{ 'mb-1': type === 'email', 'my-1': useYMargin }"
            :rounded="type === 'email' || type === 'sms' ? true : rounded"
            :closable="!disabled && !(required && tags.length === 1)"
            :confirmable="confirmBeforeRemove"
            :ok-text="okText"
            :cancel-text="cancelText"
            @close="handleRemoveTag(tag)"
          >
            <template v-slot:confirm-title>
              <p>{{
                $t('confirm_remove_item', { item: title ? title : $tc('tag') })
              }}</p>
            </template>
            {{ `${tag.length > 20 ? `${tag.slice(0, 20)}...` : tag}` }}
          </MTag>
        </template>
        {{ tag }}
      </MTooltip>
    </template>
    <template v-if="disabled && tags.length === 0 && showEmpty"> --- </template>
    <FlotoForm
      v-if="isAddMode"
      :key="renderKeyWhenAlwaysTextMode"
      ref="form"
      :show-notification="false"
      layout="vertical"
      class="flex-grow flex mb-0 items-center"
      @submit="handleAddTag"
    >
      <div class="option-text" style="max-width: 75%">
        <MValidationProvider
          ref="tagTextField"
          slim
          :rules="validationRules"
          :name="title || $tc(tagType)"
          mode="lazy"
        >
          <MFormItem
            :key="renderCount"
            slot-scope="{ errors, invalid, valid, validated }"
            style="padding-bottom: 0; margin-bottom: 0"
            :error="errors[0]"
            :validate-status="
              validated ? (valid ? 'success' : 'error') : undefined
            "
          >
            <FlotoRequesterEmail
              v-if="type === 'email'"
              v-model="tagValue"
              is-email
              :show-hint="false"
              auto-focus
              size="small"
              :advanced-searchable="false"
              v-bind="$attrs"
              @pressEnter="handleEnter"
            />
            <MAutoComplete
              v-else
              v-model="tagValue"
              :backfill="true"
              class="w-full"
              size="small"
              option-label-prop="tag"
              :loading="loading"
              auto-focus
              :placeholder="placeholder || title || $tc(tagType)"
              @pressEnter="handleEnter"
              @select="handleAutocompleteSelect"
              @search="handleAutocompleteSearch"
            >
              <template v-slot:options>
                <MSelectOption
                  v-for="option in tagOptions"
                  :key="option"
                  :tag="option"
                >
                  {{ option }}
                </MSelectOption>
              </template>
            </MAutoComplete>
          </MFormItem>
        </MValidationProvider>
      </div>
      <template v-slot:submit>
        <div class="action-bar ml-2">
          <MButton
            v-show="(alwaysTextMode && tagValue) || !alwaysTextMode"
            type="submit"
            variant="transparent"
            shape="circle"
            size="small"
            :shadow="false"
          >
            <MIcon name="check" size="lg" class="text-secondary-green" />
          </MButton>
          <MButton
            v-if="!alwaysTextMode"
            variant="transparent"
            shape="circle"
            size="small"
            :shadow="false"
            @click="isAddMode = false"
          >
            <MIcon name="times" size="lg" class="text-secondary-red" />
          </MButton>
        </div>
      </template>
    </FlotoForm>
    <input
      v-if="focusEventBrodcast"
      style="position: absolute; z-index: -1; width: 0; height: 0; padding: 0"
      @keyup="handleFocus"
    />
    <MTag
      v-if="!isAddMode && (!disabled || preview)"
      ref="addButtonRef"
      :variant="variant"
      class="flex items-center add-tag-button mx-1"
      :class="{ 'mb-1': type === 'email' }"
      :closable="false"
      @click="!disabled ? (isAddMode = true) : undefined"
    >
      <MIcon name="plus" size="sm" class="mx-1" /> {{ $t('add') }}
      {{ title || $tc(tagType, 1) }}
    </MTag>
    <slot
      v-if="!isAddMode && (!disabled || preview)"
      name="external-add-btn mx-1"
    ></slot>
  </div>
</template>

<script>
import Throttle from 'lodash/throttle'
import api from '@api'

export default {
  name: 'FlotoTagsPicker',
  model: {
    event: 'change',
  },
  props: {
    preview: {
      type: Boolean,
      default: false,
    },
    disabled: { type: Boolean, default: false },
    showEmpty: { type: Boolean, default: false },
    value: { type: Array, default: undefined },
    rounded: { type: Boolean, default: false },
    confirmBeforeRemove: { type: Boolean, default: false },
    type: { type: String, default: 'tag' },
    title: { type: String, default: undefined },
    placeholder: { type: String, default: undefined },
    alwaysTextMode: { type: Boolean, default: false },
    useYMargin: { type: Boolean, default: false },
    variant: {
      type: String,
      default: 'neutral-lighter',
    },
    // eslint-disable-next-line
    autoCompleteSearch: { type: Boolean, default: true },
    okText: {
      type: String,
      default() {
        return this.$tc('yes')
      },
    },
    cancelText: {
      type: String,
      default() {
        return this.$tc('cancel')
      },
    },
    rules: {
      type: Object,
      default: undefined,
    },
    required: { type: Boolean, default: false },
    focusEventBrodcast: { type: Boolean, default: false },
  },
  data() {
    return {
      isAddMode: !!this.alwaysTextMode,
      tagValue: undefined,
      tagOptions: [],
      renderKeyWhenAlwaysTextMode: 1,
      loading: false,
      renderCount: 1,
    }
  },
  computed: {
    validationRules() {
      let rules = {
        required: !this.alwaysTextMode,
      }
      if (this.type === 'email') {
        rules.email = true
      }
      if (this.type === 'sms') {
        rules.numeric = true
      }
      if (this.type === 'domain') {
        rules.domain = true
      }
      if (this.type === 'tag') {
        rules.max = 50
      }
      if (this.rules) {
        rules = {
          ...rules,
          ...this.rules,
        }
      }
      return rules
    },
    tagType() {
      if (this.type === 'email') {
        return 'email_plural'
      }
      if (this.type === 'sms') {
        return 'contact_no'
      }
      return this.type
    },
    tags() {
      return this.value || []
    },
  },
  watch: {
    isAddMode(newValue) {
      if (!newValue) {
        this.tagValue = undefined
      }
    },
  },
  created() {
    this.handleAutocompleteSearch = Throttle(this.handleAutocompleteSearch, 650)
  },
  methods: {
    handleFocus(e) {
      if (e.key === 'Enter') {
        this.isAddMode = true
      }
    },
    handleAddTag() {
      if (!this.tagValue) {
        return
      }
      if (this.isDuplicate(this.tagValue)) {
        this.$refs.tagTextField.setErrors([
          this.$t('same_value_exists', {
            field:
              this.title || this.type === 'tag'
                ? this.$tc(this.title || 'tag')
                : this.$tc('email_plural'),
          }),
        ])
        this.renderCount += 1
        return
      }
      const updatedValue = [...this.tags, this.tagValue]
      this.$emit('change', updatedValue)
      this.$emit('blur')
      if (this.alwaysTextMode) {
        this.$nextTick(() => {
          this.tagValue = undefined
          this.renderKeyWhenAlwaysTextMode += 1
        })
      } else {
        this.isAddMode = false
      }
    },
    isDuplicate(tag) {
      const t = this.tags.find((t) => t === tag)
      if (t) {
        return true
      }
      return false
    },
    handleRemoveTag(tag) {
      this.$nextTick(() => {
        const updatedValue = this.tags.filter((t) => t !== tag)
        this.$emit('change', updatedValue)
        this.$emit('blur')
      })
    },
    handleAutocompleteSelect(value) {
      this.tagValue = value
      this.handleAddTag()
    },
    handleAutocompleteSearch(value) {
      if (['tag'].indexOf(this.type) >= 0 && this.autoCompleteSearch) {
        this.loading = true
        this.options = []
        api
          .get(`/public/searchTags?tag=${encodeURIComponent(value)}`)
          .then((data) => {
            this.tagOptions = data
          })
          .finally(() => (this.loading = false))
      }
    },
    handleEnter(e) {
      e.stopPropagation()
      if (['tag'].indexOf(this.type) >= 0) {
        this.$nextTick(() => this.handleAddTag())
      }
    },
  },
}
</script>

<style lang="less" scoped>
.add-tag-button {
  color: var(--white-regular) !important;
  background-color: var(--primary-button-bg) !important;
  border: none;
}
</style>
